// See .cache/page-templates after running dev/build
// to understand how this file ends up looking

import React, {useEffect, useRef} from "react"
import Img from 'gatsby-image';
import styled from 'styled-components';
import {Link} from 'gatsby'
import { gsap } from "gsap";
import SplitText from "gsap/SplitText";
import RelatedPosts from '../../src/../src/components/blogSingle/RelatedPosts'
import "../../src/sass/pages/_blog-single.scss";
import {Helmet} from 'react-helmet'
import Seo from '../../src/components/seo'
import {SchemaLinksToAbsolute} from '../../src/helpers/SchemaLinksToAbsolute'
import { graphql } from 'gatsby'

gsap.registerPlugin(SplitText);
// import '../sass/pages/components/'


import BasicTextBlock from '../../src/components/page/BasicTextBlock'
import CaseStudyQuotation from '../../src/components/page/CaseStudyQuotation'
import BasicFullWidthImage from '../../src/components/page/BasicFullWidthImage'
import BasicTestimonial from '../../src/components/page/BasicTestimonial'
import ServiceCallToActionWImage from '../../src/components/page/ServiceCallToActionWImage'

const ContainsCustomColours = styled.div`

.bg-triangle .bg{
  background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2}) !important;
}

.label.cat{
  background: -webkit-linear-gradient(right, ${(props) => props.customColour1}, ${(props) => props.customColour2}) !important;
-webkit-background-clip: text !important;
-webkit-text-fill-color: transparent !important;
}

`

const PageTemplate = pageProps => {
  let data = pageProps.data;
  const seoTitle = pageProps.data.wpBlog.seo.title
  const seoDesc = pageProps.data.wpBlog.seo.metaDesc
  const todaysDate = new Date();
  const year = todaysDate.getFullYear();
  // console.log(pageProps)
data = pageProps.data.wpBlog
  const componentsArray = data.acf_pageBuilder.pageBuilder || []
  const components = componentsArray.map(component => {
    return {
      name: component.__typename.split('_').pop(),
      data: component,
    }
  })

  const heroWrap = useRef()
  
  useEffect(() => {
    if(typeof document !== 'undefined'){

      setTimeout(function(){
        heroWrap.current.classList.add('is_inview');
      }.bind(this),10);

      document.querySelector('body').setAttribute('data-bg-color', 'bg-white')
      document.querySelector('body').setAttribute('data-class', 'blog-single')


      // let text = heroText.innerHTML;
      var heroText_split = new SplitText('.hero-wrap  .hero-text-wrap h1 span', {type:"lines", linesClass:'lines'});
    
      heroText_split.lines.map(line => {
        let grandparent = line.parentNode.parentNode;
        let parent = line.parentNode;
        let originalHtml = line.innerHTML;
        let newHtml = `<div>${originalHtml}</div>`

        line.innerHTML = newHtml;

        grandparent.append(line);
        Array.from(grandparent.querySelectorAll('.lines')).map(line => {
          setTimeout(function(){
            line.classList.add('is_inview')
          }.bind(this), 1);
            
        })
        parent.style.display = 'none';

      })
    }
  }, [])

  useEffect(() => {
    if(typeof window !== 'undefined'){
      const scroller = document.querySelector('#container-scroller')
      const anchors = Array.from(document.querySelectorAll('[href^="#"]'))
      const header = document.querySelector('#header')
      const hh = header.offsetHeight
      
      if(anchors.length > 0){
        anchors.map(anchor => {
          anchor.addEventListener('click', function(e){
            e.preventDefault()
            console.log('clicked')
            const id = anchor.hash;
            const yourElement = document.querySelector(id);
            const y = yourElement.getBoundingClientRect().top + window.pageYOffset - hh - 35;
            scroller.scrollTo({top: y, behavior: 'smooth'});
          })
        })
      }
    }
  })

  const returnOGimage = () =>{
    if(pageProps.pageContext.customOgImage !== undefined){
      return process.env.GATSBY_SITE_URL+pageProps.pageContext.customOgImage.publicURL
    }else{
      return process.env.GATSBY_SITE_URL+pageProps?.pageContext?.featuredImage?.customFeaturedImage?.localFile?.publicURL
    }
  }
  const returnOGwidth = () =>{
    if(pageProps.pageContext.customOgImage !== undefined){
      return pageProps.pageContext.customOgImage.childImageSharp?.original?.width
    }else{
      return 2216
      // return pageProps?.pageContext?.featuredImage?.childImageSharp?.original?.width
    }
  }
  const returnOGheight = () =>{
    if(pageProps.pageContext.customOgImage !== undefined){
      return pageProps.pageContext.customOgImage.childImageSharp?.original?.height
    }else{
      return 2216
      // return pageProps?.pageContext?.featuredImage?.childImageSharp?.original?.height
    }
  }

  return (
    <div 
    className='outer-component-wrapper' 
    data-color1={pageProps.pageContext.color1} 
    data-color2={pageProps.pageContext.color2} 
    data-slug={pageProps.pageContext.slug}
    >
      
      <Seo 
      title={seoTitle} 
      description={seoDesc} 
      date={pageProps.pageContext.unformattedDate}
      slug={`/insights/${pageProps.pageContext.slug}/`}
      page="blogPost"
      image={returnOGimage()}
      ogWidth={returnOGwidth()}
      ogHeight={returnOGheight()}
      robotsFollow={pageProps.data.wpBlog.seo.metaRobotsNofollow}
      robotsIndex={pageProps.data.wpBlog.seo.metaRobotsNoindex}
      canonicalUrl={pageProps.data.wpBlog.seo.canonical.replace('blog', 'insights')}
      />
      {pageProps.pageContext.schema !== undefined ? 
        <SchemaLinksToAbsolute 
          schemaRaw={pageProps.pageContext.schema} 
          page={'blogPost'} 
          author={pageProps.pageContext.author.node.name}
          gravatar={pageProps.pageContext.author.node.avatar.url.replace('http://','https://')}
        /> 
      : "" }
      <Helmet>
        <script type="application/ld+json">
          {
            `{
              "@context": "https://schema.org/",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${process.env.GATSBY_SITE_URL}/insights/${pageProps.pageContext.slug}/",
                  "name": "${pageProps.pageContext.title}"
              },
              "copyrightYear": "${year}",
              "datePublished": "${pageProps.pageContext.formattedDate}",
              "dateModified": "${pageProps.pageContext.formattedModified ? pageProps.pageContext.formattedModified : pageProps.pageContext.formattedDate}",
              "headline": "${pageProps.pageContext.title}",
              "description": "${seoDesc}",
              "inLanguage": "en-gb",
              "image": {
                "@type": "ImageObject",
                "url": "${returnOGimage()}",
                "width": "${returnOGwidth()}",
                "height": "${returnOGheight()}"
              },
              "author": {
                "@type": "Person",
                "name": "${pageProps.pageContext.author.node.name}"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Edge45®",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${process.env.GATSBY_SITE_URL+pageProps.pageContext.organisationImage.publicURL}",
                  "width": "${pageProps?.pageContext?.organisationImage?.childImageSharp?.original?.width}",
                  "height": "${pageProps?.pageContext?.organisationImage?.childImageSharp?.original?.height}"
                }
              }
            
          }`
          }
        </script>
      </Helmet>
      <ContainsCustomColours id="body" className="blog-template-default single single-blog blog-single" customColour1={pageProps.pageContext.color1} customColour2={pageProps.pageContext.color1}>
        <section className="bg-triangle is_inview">
            <div className="bg"></div>
            <div className="triangle"></div>
        </section>
        <section ref={heroWrap} className={`hero-wrap hentry`} >
            <div className="wrapper-large clearfix">
                <div className="image-wrap hero--blog-wrap">
                  {pageProps?.pageContext?.featuredImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
                <Img fluid={pageProps?.pageContext?.featuredImage?.customFeaturedImage?.localFile?.childImageSharp?.fluid} alt={pageProps?.pageContext?.featuredImage?.customFeaturedImage?.altText} className="bg-cover BG bgStretch" />
                </div>
                <div className="hero-text-wrap">
                {
                    pageProps.pageContext.blogCats.nodes.map((cat) => {
                    return(
                        <Link to={cat.link.replace('blog', 'insights')}>
                        <div className="label cat">{cat.name}</div>
                        </Link>
                    )
                    })
                }
                    
                <h1 className="entry-title"><span>{pageProps.pageContext.title}</span></h1>
                <div className="excerpt"></div>
                <div className="user_info author vcard" itemProp="author">
                <div className="image_avatar">
                        <img class="avatar avatar-50 photo" src={pageProps.pageContext.author.node.avatar.url.replace('http://','https://')} alt="User" />
                    </div>
                    
                    <div className="user"><span>By </span> <Link classList="url fn" to={'/author/'+pageProps.pageContext.author.node.name.replace(/\s+/g, '-').toLowerCase()+'/'}>{pageProps.pageContext.author.node.firstName}</Link></div>
                    <div className="post_author fn hide-text"><Link classList="url fn" to={'/author/'+pageProps.pageContext.author.node.name.replace(/\s+/g, '-').toLowerCase()+'/'}>{pageProps.pageContext.author.node.name}</Link></div>
                    <div className="date updated">{pageProps.pageContext.date}</div>
                    </div>
                </div>
            </div>
        </section>
        
        {components.map((component, index) => {

      if (component.name == 'BasicTextBlock') {
        return <BasicTextBlock {...component.data} key={index} />
      }
    

      if (component.name == 'CaseStudyQuotation') {
        return <CaseStudyQuotation {...component.data} key={index} />
      }
    

      if (component.name == 'BasicFullWidthImage') {
        return <BasicFullWidthImage {...component.data} key={index} />
      }
    

      if (component.name == 'BasicTestimonial') {
        return <BasicTestimonial {...component.data} key={index} />
      }
    

      if (component.name == 'ServiceCallToActionWImage') {
        return <ServiceCallToActionWImage {...component.data} key={index} />
      }
    
          return <div>Error: The component {component.name} was not found</div>
        })}

        <RelatedPosts 
          category={pageProps.pageContext.blogCats.nodes[0].slug}
          id={pageProps.pageContext.id}
        />
      </ContainsCustomColours>
    </div>
  )
}

export default PageTemplate


    export const query = graphql`
      query PageQuery3756($id: String!) {
        wpBlog(id: {eq: $id}) {
          title
          seo{
            title
            metaDesc
            metaRobotsNofollow
            metaRobotsNoindex
            canonical
          }
          
    acf_pageBuilder {
      pageBuilder {
        __typename
         
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicAccordion {
         
        accordion {
            heading
            text
        }
        sectionTitle {
            headingType
            titleText
        }
        removeBottomMargin
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicBigBoldStatementBlockQuote {
        
   
            fieldGroupName
            removeBottomMargin
            statement
            textColour
   
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicBrandLogos {
        
      
        label
        removeBottomMargin
        removeGrayscale
        brandLogosGatsby {
          logo {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicCallToAction {
        
      
        fieldGroupName
        removeBottomMargin
        callToActionGatsby {
          ctaButtons {
            buttonText
            pageLink {
              ... on WpPage {
                id
                link
              }
            }
          }
          ctaHeading
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicFullWidthImage {
        
 
        caption
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        notFullWidth
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicFullWidthVideo {
        
      
        removeBottomMargin
        videoEmbedCode
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicGallery {
        
      
        fieldGroupName
        removeBottomMargin
        gallery {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
              relativePath
            }
          }
        }
       
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicImageGrid {
        
 
       imageGrid{
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
       }
        removeBottomMargin
 
        
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicMoreServices {
        
      
        removeBottomMargin
        pickServicesGatsby {
          service1 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service2 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service3 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service4 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
          service5 {
            ... on WpService {
              link
              acf_serviceFields {
                abbreviatedTitle
                longTitle
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicRelatedPosts {
        
      
        removeBottomMargin
        postPicker {
          buttonText
          headline
          label
          rolloverText
          pickPost {
            ... on WpBlog {
              id
              link
              title
              acf_customFeaturedImage {
                customFeaturedImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
            ... on WpCaseStudy {
              id
              link
              title
              acf_caseStudyFields {
                backgroundImage {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        }
                    }
                  }
                }
              }
            }
          }
          rolloverText
        }
        text
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicTable {
        
      
        fieldGroupName
        removeBottomMargin
        tableRow {
          isHeaderRow
          highlightRow
          tableColumns {
            tableCell
          }
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicTestimonial {
        
      
        fieldGroupName
        personPositionCompany
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        personPhotoOffsets {
          offsetX
          offsetY
        }
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicTestimonialSlider {
        
        fieldGroupName
        testimonialSlider{
          personPositionCompany
          personPhoto {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          personPhotoOffsets {
            offsetX
            offsetY
          }
          quotation
        }
        removeBottomMargin
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicTextBlock {
        
      
        addDividerLine
        removeBottomMargin
        text

    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_BasicTextBlock2col {
        
      
        addDividerLine
        removeBottomMargin
        text
        text2
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatisticArrow {
        
      
      removeBottomMargin
      animatedStatisticWArrow {
        fieldGroupName
        label
        number
        symbol
      }
    
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyAnimatedStatistics6BackgroundImage {
        
        
          fieldGroupName
          heading
          backgroundImage {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          removeBottomMargin
          text
          statistics {
            addColourBackground
            fieldGroupName
            label
            number
            symbol
          }
        
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyHeroImageBlock {
        
      
        backgroundImageFocalPoint
        backgroundImageOpacity
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        heading
        logo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        logoWidth
        removeBottomMargin
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyNumberedSlider {
        
      
        removeBottomMargin
        slider {
          imageType
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
          text
        }
        sliderLabel
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyParallaxImageText {
        
      
        backgroundColour1
        backgroundColour2
        removeBottomMargin
        text
        parallaxImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyQuotation {
        
      
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
        removeBottomMargin
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyQuotationSlider {
        
      quotations {
        labelHeading
        personPhoto {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        quotationCompany
        quotationCompanyLink
        quotationPerson
        quotationPosition
        personPositionCompany
        quotation
      }
      removeBottomMargin
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_CaseStudyTextStatBackgroundImage {
        
      
        removeBottomMargin
        backgroundImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        text
        statisticGatsby {
          label
          number
          symbol
        }
    
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServiceBarChartTextButton {
        
      
        fieldGroupName
        removeBottomMargin
        barChart {
          chartTitle
          dataValues {
            dataValue
            xDataValue
          }
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
        }
        barChart2datasets {
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xUnitLabel
          yLabel
          yUnitLabel
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
        }
        singleOrMultipleDatasets
        text
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              id
              link
            }
          }
          buttonText
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServiceCallToActionWImage {
        
      
        backgroundColor1
        backgroundColor2
        heading
        headingTag
        removeBottomMargin
        text
        buttonGatsby {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
              title
            }
          }
          buttonText
        }
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServiceIntroSection {
        
        addCountdownTimer
        countdownFrom
        heading
        removeBottomMargin
        scrollingBackgroundText
        agencyPrefixText
        source
        text
        ctaButton{
            target
              title
              url
        }
        animatedIconVideoUrl
        specifyParentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
        specifyGrandparentPage {
            ... on WpService {
                uri
                title
                acf_serviceFields {
                    abbreviatedTitle
                    longTitle
                }
            }
        }
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServiceLineChartTextButton {
        
      
        removeBottomMargin
        lineChart {
          dataSets {
            dataSetColour
            dataSetTitle
            dataValues {
              dataValue
              xDataValue
            }
          }
          chartTitle
          tooltipXLabelOptions
          tooltipXUnitOptions
          tooltipYLabelOptions
          tooltipYUnitOptions
          xLabel
          xLabelCustomTooltip
          xUnitLabel
          xUnitLabelCustomTooltip
          yLabelCustomTooltip
          yLabel
          yUnitLabel
          yUnitLabelCustomTooltip
        }
        button {
          buttonColour
          buttonLink {
            ... on WpPage {
              link
            }
          }
          buttonText
        }
        text
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServiceTextImage {
        
        useCustomBackgroundColour
        backgroundColour1
        backgroundColour2
        image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                }
            }
          }
        }
        imageFocalPoint
        imageToFillSpace
        label
        padding {
          paddingBottom
          paddingLeft
          paddingRight
          paddingTop
        }
        removeBottomMargin
        text
        textimagebutton{
          textimagebuttontext
          textimagebuttonlink
        }
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServiceTextVideo {
        
      
        backgroundColour1
        backgroundColour2
        imageToFillSpace
        video
        removeBottomMargin
        text
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_Services3Image {
        
      
        removeBottomMargin
        imagesX3 {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  }
              }
            }
          }
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServicesServicesGridVideoTitleText {
        
      
        removeBottomMargin
        servicesServicesGridVideoTitleText {
          videoUrl
          text
          title
          linkToService {
            ... on WpService {
              uri
            }
          }
        }
        servicesGridHeading
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_ServicesStaffBlock {
        
      
        servicesStaffBlockHeading
        servicesStaffBlockIntroText
        servicesStaffRepeater{
            text
            staffMember{
                ... on WpTeamMember{
                    title
                    slug
                    acf_staff_member{
                        colour
                        image{
                            altText
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1920) {
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      
    
      }
     
 
      ... on WpBlog_AcfPagebuilder_PageBuilder_VideoTranscriptionBlock {
        


        text

    
      }
    
      }
    }
  
        }
      }
    `
  